import * as React from 'react';
import styled from 'styled-components';

import {
    BreakpointWrapper,
    Breakpoints,
    Container,
    ContainerGutter,
    Text
} from '@volkswagen-onehub/components-core';
import {
    ProsConsItem,
    StyledMinusSymbol,
    StyledPlusSymbol,
    StyledSymbolContainer
} from '../../../d6/components/pros-and-cons-item';

import {ProContraItemModel as ProContraItemModelProps} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';

import {
    ResponsiveMediaRenderer,
    ResponsiveMediaRendererConf
} from '../../../components/ResponsiveMediaRenderer';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {
    RichtextFullElement,
    RichtextSimpleElement
} from '../elements/RichtextElement';
import {CopyItem} from './CopyItem';
import {useEffect, useState} from 'react';
import {
    getContainerPadding,
    getElectricTransition
} from '../../../d6/components/helpers';
import {Fade} from '../../../utils/Fade';

const throttle = require('lodash/throttle');

// animation properties
const animationDuration = 600;
const animationDistance = '30px';
const animationFraction = 0.7;
const animationDelay = 400;

const CONTAINER_PADDING_0_1 = getContainerPadding([0, 1]);

interface StyledScrollWrapperProps {
    readonly isScrolled: boolean;
}

export const StyledScrollWrapper = styled.div<StyledScrollWrapperProps>`
    ${getElectricTransition('opacity')};
    opacity: ${props => props.isScrolled && 0.3};
`;
StyledScrollWrapper.displayName = 'StyledScrollWrapper';

export const StyledStatementWrapper = styled.div`
    @media (min-width: ${Breakpoints.b560}px) {
        position: relative;
        display: flex;
        align-items: center;
    }
`;
StyledStatementWrapper.displayName = 'StyledStatementWrapper';

const ProConItemFade: React.FunctionComponent = ({children}) => (
    <Fade
        directionFrom={'bottom'}
        duration={animationDuration}
        delay={animationDelay}
        distance={animationDistance}
        fraction={animationFraction}
    >
        {children}
    </Fade>
);

interface HeadingItemProps {
    isFirstChild?: boolean;
    headline?: JSX.Element;
    copy?: JSX.Element;
    copyEmpty?: boolean;
    readonly isAnimated?: boolean;
}
const HeadlineItem = (props: HeadingItemProps): JSX.Element => {
    const {isAnimated} = props;

    let content = (
        <>
            <Container
                wrap="always"
                gutter={
                    !props.copyEmpty ? ContainerGutter.dynamic0150 : undefined
                }
            >
                {props.headline}
                <Container
                    wrap="always"
                    padding={{
                        inlineStart: CONTAINER_PADDING_0_1
                    }}
                >
                    {props.copy}
                </Container>
            </Container>
        </>
    );

    content = isAnimated ? <ProConItemFade>{content}</ProConItemFade> : content;

    return (
        <ProsConsItem isFirstChild={props.isFirstChild}>{content}</ProsConsItem>
    );
};

interface ConItemProps {
    statementCons: JSX.Element;
    labelCons: JSX.Element;
    isScrolled: boolean;
    appearance?: 'negative' | 'positive';
    reference: React.RefObject<HTMLDivElement>;
    readonly isAnimated?: boolean;
}

const ConItem = (props: ConItemProps): JSX.Element => {
    const {isAnimated} = props;

    let content = (
        <>
            <Container
                wrap="always"
                padding={{
                    inlineStart: CONTAINER_PADDING_0_1
                }}
            >
                <StyledScrollWrapper isScrolled={props.isScrolled}>
                    {props.labelCons}
                </StyledScrollWrapper>
            </Container>
            <StyledStatementWrapper>
                <BreakpointWrapper min={Breakpoints.b560}>
                    <StyledSymbolContainer>
                        <StyledMinusSymbol />
                    </StyledSymbolContainer>
                </BreakpointWrapper>
                <StyledScrollWrapper isScrolled={props.isScrolled}>
                    {props.statementCons}
                </StyledScrollWrapper>
            </StyledStatementWrapper>
        </>
    );

    content = isAnimated ? <ProConItemFade>{content}</ProConItemFade> : content;

    return (
        <ProsConsItem appearance={props.appearance}>
            <div ref={props.reference}>{content}</div>
        </ProsConsItem>
    );
};

interface ProItemProps {
    appearance?: 'negative' | 'positive';
    isLastChild?: boolean;
    mediaElement?: JSX.Element;
    descriptionPros: JSX.Element;
    labelPros: JSX.Element;
    statementPros: JSX.Element;
    readonly isAnimated?: boolean;
}

const ProItem = (props: ProItemProps): JSX.Element => {
    const {isAnimated} = props;

    let content = (
        <>
            <Container
                wrap="always"
                padding={{
                    inlineStart: CONTAINER_PADDING_0_1
                }}
            >
                {props.labelPros}
            </Container>
            <StyledStatementWrapper>
                <BreakpointWrapper min={Breakpoints.b560}>
                    <StyledSymbolContainer>
                        <StyledPlusSymbol />
                    </StyledSymbolContainer>
                </BreakpointWrapper>
                <Text bold>{props.statementPros}</Text>
            </StyledStatementWrapper>
        </>
    );

    let description = (
        <Container
            wrap="always"
            padding={{
                inlineStart: CONTAINER_PADDING_0_1
            }}
        >
            {props.descriptionPros}
        </Container>
    );

    content = isAnimated ? <ProConItemFade>{content}</ProConItemFade> : content;
    description = isAnimated ? (
        <ProConItemFade>{description}</ProConItemFade>
    ) : (
        description
    );

    return (
        <ProsConsItem
            isLastChild={props.isLastChild}
            appearance={props.appearance}
            mediaElement={props.mediaElement}
        >
            <Container wrap="always" gutter={ContainerGutter.dynamic0150}>
                {content}
                {description}
                {props.mediaElement && (
                    <BreakpointWrapper min={Breakpoints.b560}>
                        {props.mediaElement}
                    </BreakpointWrapper>
                )}
            </Container>
        </ProsConsItem>
    );
};

export type ProContraItemProps = ProContraItemModelProps &
    ProContraItemExtraProps;

export interface ProContraItemExtraProps {
    readonly firstInSection?: boolean;
    readonly lastInSection?: boolean;
}

interface StyledMediaWrapperProps {
    readonly isLastChild?: boolean;
}

const StyledMediaWrapper = styled.div<StyledMediaWrapperProps>`
    margin-bottom: ${props =>
        props.isLastChild ? 0 : props.theme.size.dynamic0120};
`;
StyledMediaWrapper.displayName = 'StyledMediaWrapper';

const responsiveMediaConfiguration: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 - 1}px)`,
        sizes: '100vw',
        aspectRatio: 'r_2_1'
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b560}px)`,
        sizes: '50vw',
        aspectRatio: 'r_2_1'
    }
];

let isScrolling: boolean = false;

export function InternalProContraItem(props: ProContraItemProps) {
    const [isScrolled, setScrolled] = useState(false);

    const scrollContainer: React.RefObject<HTMLDivElement> = React.createRef();

    const handleScroll: EventListenerOrEventListenerObject = throttle(() => {
        if (isScrolling) {
            return;
        }

        isScrolling = true;

        if (scrollContainer.current) {
            const clientViewportHeight = window.innerHeight;
            const offsetValue = scrollContainer.current.getBoundingClientRect()
                .top;
            const elementOffsetTopInPercent = Math.round(
                (offsetValue * 100) / clientViewportHeight
            );

            const isElementScrolledUp = elementOffsetTopInPercent < 20;
            if (isScrolled !== isElementScrolledUp) {
                setScrolled(isElementScrolledUp);
            }
            isScrolling = false;
        }
    }, 100); // Limit the minimum execution interval time of onscroll event

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return function cleanup(): void {
            document.removeEventListener('scroll', handleScroll);
        };
    });

    const shouldDisplayHeadline = (): boolean => {
        const {headlineEmpty, copyEmpty} = props;

        return (
            (!headlineEmpty && !copyEmpty) || !headlineEmpty || C.isInEditor()
        );
    };

    const {firstInSection, lastInSection, copyEmpty, mediaNotEmpty} = props;

    const headline = <HeadingElement path="headline" style={'H1'} order="H2" />;

    const copy = <CopyItem path="copy" />;

    const statementCons = (
        <HeadingElement path="conStatement" style={'H2'} order="H3" />
    );

    const labelCons = <RichtextSimpleElement path="conLabel" />;

    const statementPros = (
        <HeadingElement path="proStatement" style={'H2'} order="H3" />
    );

    const labelPros = <RichtextSimpleElement path="proLabel" />;

    const descriptionPros = <RichtextFullElement path="proDescription" />;

    const mediaElement =
        mediaNotEmpty || C.isInEditor() ? (
            <ResponsiveMediaRenderer configs={responsiveMediaConfiguration}>
                <StyledMediaWrapper isLastChild={lastInSection}>
                    <MediaElement
                        path="media"
                        responsiveMediaConfig={responsiveMediaConfiguration}
                    />
                </StyledMediaWrapper>
            </ResponsiveMediaRenderer>
        ) : (
            undefined
        );

    return (
        <AuthoringWrapper
            title="Pro Contra Item"
            bgColor={AuthoringWrapper.BG_COLOR_ITEM}
        >
            {firstInSection && shouldDisplayHeadline() && (
                <HeadlineItem
                    headline={headline}
                    copy={copy}
                    isFirstChild={firstInSection}
                    copyEmpty={copyEmpty}
                    isAnimated={!C.isInEditor()}
                />
            )}
            <ConItem
                isAnimated={!C.isInEditor()}
                statementCons={statementCons}
                labelCons={labelCons}
                isScrolled={isScrolled}
                reference={scrollContainer}
                appearance="negative"
            />
            <ProItem
                isAnimated={!C.isInEditor()}
                isLastChild={lastInSection}
                statementPros={statementPros}
                labelPros={labelPros}
                descriptionPros={descriptionPros}
                mediaElement={mediaElement}
                appearance="positive"
            />
        </AuthoringWrapper>
    );
}

export const ProContraItem = MapTo<ProContraItemExtraProps>(
    'vwa-ngw18/components/editorial/items/proContraItem',
    InternalProContraItem
);
