import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    Layout,
    LayoutRowGap
} from '@volkswagen-onehub/components-core';
import {StepByStepSection as StepByStepSectionComp} from '../../../d6/components/step-by-step';
import {getContainerPadding} from '../../../d6/components/helpers';
import {StepByStepSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    MediaContext,
    MediaContextData
} from '../../../context/media/MediaContext';
import {ContainerItem} from '../../../infrastructure/ContainerExporter';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {getChildItems} from '../../../utils/container/getContainerChildItems';
import {Parsys, ParsysType} from '../../structure/Parsys';
import {HeadingElement} from '../elements/HeadingElement';
import {CopyItem} from '../items/CopyItem';
import {StepByStepItem} from '../items/StepByStepItem';
import {SectionWrapper} from './SectionWrapper';
import {Model} from '@adobe/cq-react-editable-components';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const PARSYS_PATH = 'stepByStepSectionParsys';

const CONTAINER_PADDING_2_1 = getContainerPadding([2, 1]);
const CONTAINER_PADDING_2_5 = getContainerPadding([2, 5]);
const CONTAINER_PADDING_2_6 = getContainerPadding([2, 6]);
const CONTAINER_PADDING_2_3_5 = getContainerPadding([2, 3, 5]);

const MEDIA_CONTEXT: MediaContextData = {sizes: '100vw'};

const getFormattedStepId = (stepId: number): string => {
    return `00 ${stepId.toString()}`.slice(-2);
};

const renderItems = (model: Model): JSX.Element[] =>
    getChildItems(model).map((child: ContainerItem, i: number) => {
        return (
            <CyAttributeAppender name="stepItem" key={child.key}>
                <StepByStepItem
                    key={child.key}
                    path={`${PARSYS_PATH}/${child.key}`}
                    isFirstChild={i === 0}
                    stepId={getFormattedStepId(i + 1)}
                />
            </CyAttributeAppender>
        );
    });

function InternalStepByStepSection(props: StepByStepSectionModel): JSX.Element {
    const {
        anchorId,
        cqItems,
        contentId: sectionId,
        contentLabels,
        contentName,
        tagTypeBroken,
        mboxId
    } = props;

    const renderItemsCallback = React.useCallback(
        () => renderItems(cqItems[PARSYS_PATH]),
        [cqItems]
    );

    const copy = (
        <Container
            padding={{
                inlineStart: CONTAINER_PADDING_2_6,
                inlineEnd: CONTAINER_PADDING_2_6,
                bottom: ContainerPadding.dynamic0270
            }}
        >
            <CopyItem path="copy" useDropCap={false} />
        </Container>
    );

    const isCopyValid = cqItems.copy && !cqItems.copy.empty;
    const headlineComponent = (
        <Layout rowGap={LayoutRowGap.dynamic0100} allowOverflowingContent>
            <Container
                padding={{
                    inlineStart: CONTAINER_PADDING_2_5,
                    inlineEnd: CONTAINER_PADDING_2_6,
                    bottom: ContainerPadding.dynamic0100
                }}
                wrap="always"
            >
                <HeadingElement path="heading" order="H2" />
            </Container>
            {isCopyValid && copy}
        </Layout>
    );

    const headline =
        cqItems.heading && !cqItems.heading.empty
            ? headlineComponent
            : undefined;

    return C.isInEditor() ? (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S112 Step By Step Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <Layout>
                <Container
                    stretchContent
                    wrap="always"
                    padding={{
                        left: CONTAINER_PADDING_2_3_5,
                        right: CONTAINER_PADDING_2_3_5
                    }}
                    gutter={ContainerGutter.dynamic0100}
                >
                    <HeadingElement path="heading" order="H2" style="H2" />
                    <Container
                        padding={{
                            inlineStart: CONTAINER_PADDING_2_1
                        }}
                    >
                        <CopyItem path="copy" useDropCap={false} />
                    </Container>
                    <Parsys
                        parsysType={ParsysType.SECTION_PARSYS}
                        path={PARSYS_PATH}
                    />
                </Container>
            </Layout>
        </AuthoringWrapper>
    ) : (
        <SectionWrapper
            anchorId={anchorId}
            sectionId={sectionId}
            contentLabels={contentLabels}
            contentName={contentName}
            mboxId={mboxId}
        >
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <Layout allowOverflowingContent>
                    <MediaContext.Provider value={MEDIA_CONTEXT}>
                        <CyAttributeAppender name="stepByStepSection">
                            <StepByStepSectionComp headline={headline}>
                                {renderItemsCallback()}
                            </StepByStepSectionComp>
                        </CyAttributeAppender>
                    </MediaContext.Provider>
                </Layout>
            </GeneralDisclaimerProvider>
        </SectionWrapper>
    );
}

export const StepByStepSection = MapTo(
    'vwa-ngw18/components/editorial/sections/stepByStepSection',
    InternalStepByStepSection
);
