import {
    Breakpoints,
    Container,
    ContainerGutter,
    CTA,
    Text,
    TokenTextAppearance,
    TextTag,
    styled
} from '@volkswagen-onehub/components-core';
import {ArrowUp} from '@volkswagen-onehub/icons-core';
import {getStartDirection} from '../../../../d6/components/helpers';
import * as React from 'react';

import {GlossaryGroupModel} from '../../../../../generated/core';
import {AnchorTarget} from '../../../../components/AnchorTarget';
import {GLOSSARY_ID} from '../../../../components/glossary/Glossary';
import {GlossaryItem} from '../../items/GlossaryItem';

const StyledLetterWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-left: ${props => props.theme.size.grid005};
        padding-right: ${props => props.theme.size.grid006};
    }
`;

const StyledGoBackButtonWrapper = styled.div`
    margin-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.static100};
`;

interface GlossaryGroupExtraProps {
    readonly goBackButtonText: string;
    onBackButtonClick(): void;
}

export class GlossaryGroup extends React.Component<
    GlossaryGroupModel & GlossaryGroupExtraProps
> {
    public render(): JSX.Element {
        const {
            letter,
            glossaryItems = [],
            anchorTargetId,
            onBackButtonClick,
            goBackButtonText
        } = this.props;

        return (
            <AnchorTarget anchorId={anchorTargetId}>
                <Container wrap="always" gutter={ContainerGutter.dynamic0100}>
                    <StyledLetterWrapper>
                        <Text
                            appearance={TokenTextAppearance.headline400}
                            tag={TextTag.h2}
                            bold
                        >
                            {letter}
                        </Text>
                    </StyledLetterWrapper>
                    <Container
                        gutter={ContainerGutter.dynamic0150}
                        wrap="always"
                    >
                        {glossaryItems.map(item => (
                            <GlossaryItem key={item} path={item} />
                        ))}
                    </Container>
                    <StyledLetterWrapper>
                        <StyledGoBackButtonWrapper>
                            <CTA
                                tag="a"
                                emphasis="tertiary"
                                onClick={onBackButtonClick}
                                href={`#${GLOSSARY_ID}`}
                            >
                                <ArrowUp variant="small" />
                                {goBackButtonText}
                            </CTA>
                        </StyledGoBackButtonWrapper>
                    </StyledLetterWrapper>
                </Container>
            </AnchorTarget>
        );
    }
}
