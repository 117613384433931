import * as React from 'react';

import {
    Container,
    ContainerGutter,
    Text,
    TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import {
    CarFeatureCategoryItemModel,
    RtNodeUnion
} from '../../../../generated/core';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {Richtext} from '../../../components/Richtext';
import {CmsTextLink} from '../../../components/links/CmsTextLink';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {XfCarFeatureCategoryMain} from '../../structure/main/XfCarFeatureCategoryMain';

class InternalCarFeatureCategoryItem extends React.PureComponent<
    CarFeatureCategoryItemModel
> {
    public render(): JSX.Element | null {
        return (
            <AuthoringWrapper
                title="Car Feature Category Item"
                bgColor={AuthoringWrapper.BG_COLOR_ITEM}
            >
                <Container
                    wrap="always"
                    stretchContent
                    gutter={ContainerGutter.static400}
                >
                    {this.renderCategory()}
                    <Container
                        wrap="always"
                        gutter={ContainerGutter.static350}
                        stretchContent
                    >
                        {this.renderFeatureLinks()}
                    </Container>
                </Container>
            </AuthoringWrapper>
        );
    }

    private renderCategory(): JSX.Element | null {
        const hasCategoryXf = !!this.props.cqItems.category;

        if (hasCategoryXf) {
            return <XfCarFeatureCategoryMain path={'category'} isEmbedded />;
        } else if (C.isInEditor()) {
            return (
                <AlertBox
                    severity={AlertBoxSeverity.WARNING}
                    key={'alert_category'}
                >
                    <span>Warning: no category XF configured</span>
                </AlertBox>
            );
        } else {
            return null;
        }
    }

    private renderFeatureLinks(): JSX.Element[] {
        const {featureUrls, featureTitles, featureRtTitles} = this.props;
        const elements: JSX.Element[] = [];
        const hasFeatureLinks = featureUrls && featureUrls.length > 0;

        if (hasFeatureLinks) {
            for (let i = 0; i < featureUrls.length; i++) {
                const url = featureUrls[i];
                const title = featureTitles[i];
                const key = `item_${i}`;
                const hasRichtext = featureRtTitles.length > i;

                if (hasRichtext) {
                    const titleLink: RtNodeUnion[] = [
                        {
                            kind: 'linkElement',
                            tagName: 'a',
                            href: url,
                            title,
                            target: '_self',
                            children: featureRtTitles[i],
                            layerLink: true,
                            layerType: 'CONTENT'
                        }
                    ];
                    elements.push(
                        <Richtext
                            key={key}
                            richtext={titleLink}
                            appearance={TokenTextAppearance.bigcopy200}
                            bold
                        />
                    );
                } else {
                    elements.push(
                        <Text
                            key={key}
                            appearance={TokenTextAppearance.bigcopy200}
                            bold
                        >
                            <CmsTextLink href={url} title={title} isLayerLink>
                                {title}
                            </CmsTextLink>
                        </Text>
                    );
                }
            }
        } else if (C.isInEditor()) {
            elements.push(
                <AlertBox
                    severity={AlertBoxSeverity.WARNING}
                    key={'alert_feature'}
                >
                    <span>Warning: no feature XFs configured</span>
                </AlertBox>
            );
        }

        return elements;
    }
}

export const CarFeatureCategoryItem = MapTo(
    'vwa-ngw18/components/editorial/items/carFeatureCategoryItem',
    InternalCarFeatureCategoryItem
);
