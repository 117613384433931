import * as React from 'react';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerPadding,
    Layout,
    ThemeProvider
} from '@volkswagen-onehub/components-core';

import {PreBookingTeaserElementModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {ResponsiveMediaRenderer} from '../../../components/ResponsiveMediaRenderer';
import {OverlayDisclaimers} from '../../../components/disclaimers/OverlayDisclaimerContainers';
import {Parsys, ParsysType} from '../../structure/Parsys';
import {ButtonElement} from './buttonElement/ButtonElement';
import {
    StyledContentWrapper,
    StyledMediaWrapper,
    StyledCountdownContainer as StyledPrebookingContainer,
    StyledStageWrapper,
    responsiveCountdownMediaConfig
} from './CountdownTeaserElement';
import {HeadingElement} from './HeadingElement';
import {MediaElement} from './MediaElement';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const PARSYS_PATH = 'preBookingTeaserElementParsys';

const layoutConfigurationHalfSwitched = {
    [Breakpoints.default]: [
        {name: 'a', columns: 24},
        {name: 'b', columns: 24}
    ],
    [Breakpoints.b560]: [
        {name: 'b', columns: 12},
        {name: 'a', columns: 12}
    ]
};

class InternalPreBookingTeaserElement extends React.Component<
    PreBookingTeaserElementModel
> {
    public render(): JSX.Element {
        const inEditor = C.isInEditor();

        const mediaElement = (
            <MediaElement
                path="media"
                containerProps={{matchParent: true}}
                useParentAspectRatio={true}
                hideItemBasedDisclaimers={!inEditor}
                responsiveMediaConfig={responsiveCountdownMediaConfig}
            />
        );

        const wrappedMediaElement = (
            <ResponsiveMediaRenderer configs={responsiveCountdownMediaConfig}>
                <StyledMediaWrapper>{mediaElement}</StyledMediaWrapper>
            </ResponsiveMediaRenderer>
        );

        const countdownHeadingElement = (
            <HeadingElement
                path="heading"
                hideItemDisclaimers
                style="H2"
                order="H2"
            />
        );

        const buttonLink = <ButtonElement path="button" />;

        return (
            <AuthoringWrapper
                title="Pre-Booking Element"
                bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            >
                <ThemeProvider theme={inEditor ? 'main' : 'inverted'}>
                    <StyledStageWrapper>
                        <GeneralDisclaimerProvider
                            displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                        >
                            <>
                                {!inEditor && wrappedMediaElement}
                                <StyledContentWrapper>
                                    <Layout
                                        appearance={
                                            layoutConfigurationHalfSwitched
                                        }
                                        allowOverflowingContent
                                    >
                                        <StyledPrebookingContainer>
                                            <Container
                                                padding={{
                                                    bottom:
                                                        ContainerPadding.dynamic0150
                                                }}
                                                wrap="always"
                                            >
                                                {countdownHeadingElement}
                                            </Container>
                                            <Container
                                                wrap="always"
                                                gutter={
                                                    ContainerGutter.dynamic0100
                                                }
                                            >
                                                <Parsys
                                                    path={PARSYS_PATH}
                                                    parsysType={
                                                        ParsysType.ITEM_PARSYS
                                                    }
                                                />
                                            </Container>
                                            <Container
                                                padding={{
                                                    top:
                                                        ContainerPadding.dynamic0250,
                                                    bottom:
                                                        ContainerPadding.dynamic0300
                                                }}
                                            >
                                                {buttonLink}
                                            </Container>
                                        </StyledPrebookingContainer>
                                        {inEditor ? mediaElement : <></>}
                                        {/* needed for the positioning of containers in two column layout */}
                                    </Layout>
                                </StyledContentWrapper>
                                {inEditor ? null : (
                                    <OverlayDisclaimers
                                        displayTypes={[
                                            'T3_SECTION_BASED',
                                            'T4_ITEM_BASED'
                                        ]}
                                        color={'light'}
                                        type="item"
                                    />
                                )}
                            </>
                        </GeneralDisclaimerProvider>
                    </StyledStageWrapper>
                </ThemeProvider>
            </AuthoringWrapper>
        );
    }
}

export const PreBookingTeaserElement = MapTo(
    'vwa-ngw18/components/editorial/elements/preBookingTeaserElement',
    InternalPreBookingTeaserElement
);
