import throttle from 'lodash.throttle';
import * as React from 'react';
import ReactDOM from 'react-dom';
import {ThemedStyledProps} from 'styled-components';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    Text,
    TokenTextAppearance,
    styled
} from '@volkswagen-onehub/components-core';
import {getEndDirection, getStartDirection} from '../helpers';

export interface StepByStepSectionProps {
    readonly headline?: React.ReactNode;
    readonly children: React.ReactElement<StepByStepItemProps>[];
}

interface StyledStepByStepItemProps {
    readonly description: boolean;
    readonly mediaPortrait: boolean;
}

interface StyledLineWrapperProps {
    readonly scrolledToBottom: boolean;
}

const lineDotSize = '10px';
const lineWidth = '2px';

const ANIMATION_DURATION = 200;
const ANIMATION_FRACTION = 0.75;

const THROTTLE_INTERVAL_IN_MS = 25;

const StyledLineWrapper = styled.div<StyledLineWrapperProps>`
    position: absolute;
    height: 100%;
    top: 0;
    ${props => getStartDirection(props.theme.direction)}: ${props =>
        props.theme.size.grid003};
    padding: ${props => props.theme.size.dynamic0050} 0;
    display: flex;
    justify-content: center;
    width: ${props => props.theme.size.grid001};

    @media (min-width: ${Breakpoints.b1600}px) {
        padding: 0;
        ${props => getStartDirection(props.theme.direction)}: ${props =>
            props.theme.size.grid011};

        &:before {
            background-color: ${props => props.theme.colors.signal.progress};
            display: block;
            content: '';
            height: ${lineDotSize};
            width: ${lineDotSize};
            border-radius: 50%;
            position: absolute;
        }
    }

    &:after {
        background-color: ${props =>
            props.scrolledToBottom
                ? props.theme.colors.signal.progress
                : props.theme.colors.border.divider};
        display: block;
        content: '';
        height: ${lineDotSize};
        width: ${lineDotSize};
        border-radius: 50%;
        position: absolute;
        bottom: 0;
    }
`;
StyledLineWrapper.displayName = 'StyledLineWrapper';

const StyledLine = styled.div`
    background-color: ${props => props.theme.colors.border.divider};
    height: 100%;
    min-height: ${props => props.theme.size.dynamic0270};
    width: ${lineWidth};
    display: flex;
    justify-content: center;

    @media (min-width: ${Breakpoints.b1600}px) {
        /*
		 * Since the line moves between stepId and content we need to add the stepId font size
		 * in order to keep the dynamic270 min-height.
		 */
        min-height: calc(
            ${props => props.theme.size.dynamic0270} +
                ${props => props.theme.fonts.headline[300].fontSize}
        );
    }
`;
StyledLine.displayName = 'StyledLine';

interface StyledAnimatedLineProps {
    size: number;
}

const StyledAnimatedLine = styled(StyledLine).attrs<StyledAnimatedLineProps>(
    (props: ThemedStyledProps<StyledAnimatedLineProps, 'main'>) => ({
        style: {
            height: `${props.size}px`
        }
    })
)<StyledAnimatedLineProps>`
    position: absolute;
    top: 0;
    background-color: ${props => props.theme.colors.signal.progress};
    transform-origin: top;
    transition: height 50ms;
    height: 0;
    min-height: calc(${props => props.theme.fonts.headline[300].fontSize} * 2);
`;
StyledAnimatedLine.displayName = 'StyledAnimatedLine';

const StyledStepByStepContainer = styled.div`
    position: relative;
    padding-top: ${props => props.theme.size.dynamic0200};
`;
StyledStepByStepContainer.displayName = 'StyledStepByStepContainer';

interface StepByStepSectionState {
    lineSize: number;
    scrolledToBottom: boolean;
}

export class StepByStepSection extends React.PureComponent<
    StepByStepSectionProps,
    StepByStepSectionState
> {
    private readonly scrollContainer: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    // tslint:disable-next-line:readonly-keyword
    private isScrolling: boolean = false;

    private readonly handleScroll = throttle((): void => {
        if (this.isScrolling) {
            return;
        }
        this.isScrolling = true;
        const scrollContainerNode = ReactDOM.findDOMNode(
            this.scrollContainer.current
        );
        if (scrollContainerNode instanceof Element) {
            const clientViewportHeight = window.innerHeight;
            const scrollContainerRect = scrollContainerNode.getBoundingClientRect();
            const offsetTop = scrollContainerRect.top;
            const offsetBottom =
                clientViewportHeight - scrollContainerRect.bottom;
            const height = scrollContainerRect.height;
            let adjustedVisibleHeight = 0;
            let scrolledToBottom = false;

            if (
                offsetTop <= clientViewportHeight * 0.75 &&
                offsetBottom < clientViewportHeight / 4
            ) {
                adjustedVisibleHeight =
                    clientViewportHeight - offsetTop - clientViewportHeight / 4;
            } else if (offsetBottom >= clientViewportHeight / 4) {
                adjustedVisibleHeight = height;
                scrolledToBottom = true;
            }

            this.setState({lineSize: adjustedVisibleHeight, scrolledToBottom});

            this.isScrolling = false;
        }
    }, 25); // Limit the minimum execution interval time of onscroll event

    public readonly state: StepByStepSectionState = {
        lineSize: 0,
        scrolledToBottom: false
    };

    public componentDidMount(): void {
        this.handleScroll();
        document.addEventListener('scroll', this.handleScroll);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('scroll', this.handleScroll);
    }

    public render(): React.ReactNode {
        const {children, headline} = this.props;
        const {lineSize, scrolledToBottom} = this.state;

        return (
            <Container
                gutter={ContainerGutter.static400}
                horizontalAlign="center"
                wrap="always"
            >
                {headline}
                <StyledStepByStepContainer ref={this.scrollContainer}>
                    <StyledLineWrapper scrolledToBottom={scrolledToBottom}>
                        <StyledLine />
                        <StyledAnimatedLine size={lineSize} />
                    </StyledLineWrapper>
                    {children}
                </StyledStepByStepContainer>
            </Container>
        );
    }
}

const StyledCaptionWrapper = styled.div`
    grid-area: capt;
    display: flex;
    align-items: flex-end;
`;
StyledCaptionWrapper.displayName = 'StyledCaptionWrapper';

const StyledStepIdWrapper = styled.div`
    grid-area: step;
    text-align: center;
    background: ${props => props.theme.colors.background.primary};
    position: relative;

    &:before {
        content: '';
        position: absolute;
        background: ${props => props.theme.colors.background.primary};
        width: 100%;
        height: ${props => props.theme.size.dynamic0050};
        left: 0;
        top: 0;
        transform: translateY(-100%);
    }
    &:after {
        content: '';
        position: absolute;
        background: ${props => props.theme.colors.background.primary};
        width: 100%;
        height: ${props => props.theme.size.dynamic0050};
        left: 0;
        bottom: 0;
        transform: translateY(100%);
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        text-align: ${props => getEndDirection(props.theme.direction)};

        &:before {
            content: none;
        }

        &:after {
            content: none;
        }
    }
`;
StyledStepIdWrapper.displayName = 'StyledStepIdWrapper';

const StyledStepByStepItem = styled.div<StyledStepByStepItemProps>`
    padding-bottom: ${props => props.theme.size.dynamic0270};
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-areas: ${props => {
        let areas = `'.    .    step step step .    capt capt capt capt capt capt capt capt capt capt capt capt capt capt capt capt .    .'
					'.    .    .    line .    .    capt capt capt capt capt capt capt capt capt capt capt capt capt capt capt capt .    .'
					'.    .    .    line .    .    desc desc desc desc desc desc desc desc desc desc desc desc desc desc desc desc .    .'`;
        if (props.mediaPortrait) {
            areas += `\n'.    .    .    line .    .    img  img  img  img  img  img  img  img  img  img  img  img  img  .    .    .    .    .'`;
        } else {
            areas += `\n'.    .    .    line .    .    img  img  img  img  img  img  img  img  img  img  img  img  img  img  img  img  .    .'`;
        }

        return areas;
    }};

    @media (min-width: ${Breakpoints.b1600}px) {
        grid-template-areas: ${props => {
            let areas = `'.    .    .    .    .    step step step step step step line capt capt capt capt capt capt capt .    .    .    .    .'`;
            if (props.description && props.mediaPortrait) {
                areas += `\n'.    .    .    .    .    .    .    img  img  img  img  line desc desc desc desc desc desc desc desc .    .    .    .'`;
            } else if (props.description) {
                areas += `\n'.    .    .    .    .    img  img  img  img  img  img  line desc desc desc desc desc desc desc desc .    .    .    .'`;
            } else if (props.mediaPortrait) {
                areas += `\n'.    .    .    .    .    .    .    .    .    .    .    line img  img  img  img  .    .    .    .    .    .    .    .'`;
            } else {
                areas += `\n'.    .    .    .    .    .    .    .    .    .    .    line img  img  img  img  img  img  .    .    .    .    .    .'`;
            }

            return areas;
        }};
    }
`;
StyledStepByStepItem.displayName = 'StyledStepByStepItem';

const StyledCaption = styled.div`
    // needed to bottom align the caption with the Id
    margin: calc(var(--textappearances-headline450-fontsize) * 0.11) 0;

    > div {
        // removes the default margin of text so it is aligned with stepId
        // TODO: remove after font is fixed
        margin: 0;
    }
`;
StyledCaption.displayName = 'StyledCaption';

interface StyledDescriptionWrapperProps {
    readonly topOffset: number;
}

const StyledDescriptionWrapper = styled.div.attrs<
    StyledDescriptionWrapperProps
>((props: ThemedStyledProps<StyledDescriptionWrapperProps, 'main'>) => ({
    style: {
        transform: `translateY(${props.topOffset}px)`
    }
}))<StyledDescriptionWrapperProps>`
    transition: transform ${ANIMATION_DURATION}ms ease-in;
    grid-area: desc;
    padding-top: ${props => props.theme.size.dynamic0100};
`;
StyledDescriptionWrapper.displayName = 'StyledDescriptionWrapper';

interface OpacityWrapperProps {
    readonly contentOpacity: number;
}

const OpacityWrapper = styled.div.attrs<OpacityWrapperProps>(
    (props: ThemedStyledProps<OpacityWrapperProps, 'main'>) => ({
        style: {
            opacity: props.contentOpacity
        }
    })
)<OpacityWrapperProps>`
    transition: opacity ${ANIMATION_DURATION}ms;
`;

const StyledMediaWrapper = styled.div`
    grid-area: img;
    padding-top: ${props => props.theme.size.dynamic0100};
`;
StyledMediaWrapper.displayName = 'StyledMediaWrapper';

export interface StepByStepItemProps {
    readonly stepId: string;
    readonly caption: React.ReactNode;
    readonly description?: React.ReactNode;
    readonly media?: React.ReactNode;
    readonly mediaFormat?: 'landscape' | 'portrait';
    readonly isFirstChild?: boolean;
}

interface StepByStepItemState {
    readonly descriptionOffset: number;
    readonly mediaOpacity: number;
    readonly stepIdOpacity: number;
}

export class StepByStepItem extends React.PureComponent<
    StepByStepItemProps,
    StepByStepItemState
> {
    private readonly descriptionScrollContainer: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    private readonly mediaScrollContainer: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    private readonly stepIdScrollContainer: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    // tslint:disable-next-line:readonly-keyword
    private isScrolling: boolean = false;

    private readonly handleScroll = throttle((): void => {
        if (this.isScrolling) {
            return;
        }
        this.isScrolling = true;

        let descriptionOffset = 0;
        let stepIdOpacity = 0.1;
        let mediaOpacity = 0;

        const clientViewportHeight = window.innerHeight;

        const stepIdScrollContainer = ReactDOM.findDOMNode(
            this.stepIdScrollContainer.current
        );
        if (stepIdScrollContainer instanceof Element) {
            const scrollContainerRect = stepIdScrollContainer.getBoundingClientRect();
            const offsetTop = scrollContainerRect.top;

            if (offsetTop < clientViewportHeight * ANIMATION_FRACTION) {
                stepIdOpacity = 1;
            }

            const descriptionScrollContainer = ReactDOM.findDOMNode(
                this.descriptionScrollContainer.current
            );
            if (descriptionScrollContainer) {
                const stepIdHeight = scrollContainerRect.height;

                descriptionOffset =
                    offsetTop + stepIdHeight <
                    clientViewportHeight * ANIMATION_FRACTION
                        ? 0
                        : clientViewportHeight * 0.25;
            }

            this.isScrolling = false;
        }

        const mediaScrollContainer = ReactDOM.findDOMNode(
            this.mediaScrollContainer.current
        );
        if (mediaScrollContainer instanceof Element) {
            const scrollContainerRect = mediaScrollContainer.getBoundingClientRect();
            const offsetTop = scrollContainerRect.top;
            const height = scrollContainerRect.height;

            if (offsetTop < clientViewportHeight * ANIMATION_FRACTION) {
                const mediaTopDistanceFromHighlightedLine =
                    clientViewportHeight * ANIMATION_FRACTION - offsetTop;
                const mediaFractionToHighlightedLine =
                    mediaTopDistanceFromHighlightedLine / height;
                const toPercent =
                    Math.round(mediaFractionToHighlightedLine * 100) / 100;
                const mediaPercentAboveHighlightedLine = Math.max(
                    toPercent * 2,
                    0
                );
                mediaOpacity = mediaPercentAboveHighlightedLine;
            }
            this.isScrolling = false;
        }

        this.setState({
            descriptionOffset,
            mediaOpacity,
            stepIdOpacity
        });
    }, THROTTLE_INTERVAL_IN_MS); // Limit the minimum execution interval time of onscroll event

    public readonly state: StepByStepItemState = {
        descriptionOffset: 0,
        mediaOpacity: 1,
        stepIdOpacity: 1
    };

    public componentDidMount(): void {
        if (!this.props.isFirstChild) {
            this.handleScroll();
            document.addEventListener('scroll', this.handleScroll);
        }
    }

    public componentWillUnmount(): void {
        if (!this.props.isFirstChild) {
            document.removeEventListener('scroll', this.handleScroll);
        }
    }

    public render(): JSX.Element {
        const {
            stepId,
            caption,
            description,
            media,
            mediaFormat = 'landscape'
        } = this.props;
        const {descriptionOffset, mediaOpacity, stepIdOpacity} = this.state;

        return (
            <StyledStepByStepItem
                description={Boolean(description)}
                mediaPortrait={mediaFormat === 'portrait'}
            >
                <StyledStepIdWrapper>
                    <div ref={this.stepIdScrollContainer}>
                        <OpacityWrapper contentOpacity={stepIdOpacity}>
                            <Text
                                appearance={TokenTextAppearance.headline600}
                                bold
                            >
                                {stepId}
                            </Text>
                        </OpacityWrapper>
                    </div>
                </StyledStepIdWrapper>
                <StyledCaptionWrapper>
                    <StyledCaption>
                        <OpacityWrapper contentOpacity={stepIdOpacity}>
                            <Text appearance={TokenTextAppearance.headline300}>
                                {caption}
                            </Text>
                        </OpacityWrapper>
                    </StyledCaption>
                </StyledCaptionWrapper>
                {description && (
                    <StyledDescriptionWrapper topOffset={descriptionOffset}>
                        <div ref={this.descriptionScrollContainer}>
                            <Text appearance={TokenTextAppearance.copy200}>
                                {description}
                            </Text>
                        </div>
                    </StyledDescriptionWrapper>
                )}
                {media && (
                    <StyledMediaWrapper>
                        <OpacityWrapper contentOpacity={mediaOpacity}>
                            <div ref={this.mediaScrollContainer}>{media}</div>
                        </OpacityWrapper>
                    </StyledMediaWrapper>
                )}
            </StyledStepByStepItem>
        );
    }
}
